<template>
  <section>
      <div class="content-header">
        <h2>Blog posts</h2>
      </div>
      <div class="content-wrapper">
        <ul>
          <li class="pb-3"><a href="https://www.insidehighered.com/advice/2020/03/17/10-strategies-support-students-and-help-them-learn-during-coronavirus-crisis" target="_blank">Hope matters: 10 strategies support students and help them learn during coronavirus crisis</a></li>
          <li class="pb-3"><a href="https://www.editage.com/insights/7-secrets-to-help-you-build-academic-resilience" target="_blank">7 Secrets to help you build academic resilience</a></li>
          <li class="pb-3"><a href="https://blog.innerdrive.co.uk/how-to-improve-academic-buoyancy" target="_blank">InnerDrive. How to Improve Academic Buoyancy</a></li>
          <li><a href="https://www.psychreg.org/academic-resilience/" target="_blank">How can we build academic resilience?</a></li>
        </ul>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '01',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
